* {
  margin: 0;
  padding: 0;
  border: 0;
}

@font-face {
  font-family: Avenir-Regular;
  src: url('./fonts/AvenirNextCyr-Regular.ttf');
}

@font-face {
  font-family: Avenir-Demi;
  src: url('./fonts/AvenirNextCyr-Demi.ttf');
}

@font-face {
  font-family: Avenir-Medium;
  src: url('./fonts/AvenirNextCyr-Medium.ttf');
}

@font-face {
  font-family: Avenir-Bold;
  src: url('./fonts/AvenirNextCyr-Bold.ttf');
}

/* GERAL */
body {
  font-family: Avenir-Regular;
}

#header {
  width: 100%;
  margin: auto;
  display: block;
}

#content {
  max-width: 1200px;
  margin: auto;
  padding-bottom: 80px;
}

h1 {
  font-family: Avenir-Demi;
  font-size: 1.5rem;
  text-transform: uppercase;
}

h2 {
  color: rgb(244, 160, 137);
  font-family: Avenir-Bold;
  font-size: 1.2rem;
}

h3 {
  font-family: Avenir-Medium;
  font-size: 1rem;
}

p {
  font-family: Avenir-Regular;
  font-size: 1rem;
  line-height: 1.6rem;
  margin-bottom: 0;
}

a,
a:link,
a:visited,
a:hover,
a:active {
  text-decoration: none;
  color: white;
}

li {
  list-style-type: none;
}

#aviao {
  display: none;
}

#mala {
  display: none;
}

#chapeu {
  display: none;
}

/* ICONES */
#icones {
  position: absolute;
  top: 0;
  z-index: 99999;
  overflow: hidden;
  right: calc(50% - 60px);
}

.icone {
  width: 30px;
  margin: 30px 5px;
  opacity: 0.7;
  transition: opacity .3s ease-in-out;
  -moz-transition: opacity .3s ease-in-out;
  -webkit-transition: opacity .3s ease-in-out;
  -o-transition: opacity .3s ease-in-out;
}

#ic1:hover {
  opacity: 1;
  cursor: pointer;
}

#ic2:hover {
  opacity: 1;
  cursor: pointer;
}

#ic3:hover {
  opacity: 1;
  cursor: pointer;
}

/* SOBRE MIM */
#primeira {
  position: relative;
  overflow: hidden;
}

#sobre {
  position: absolute;
  top: 180px;
  left: 430px;
}

#primeira p {
  width: 400px;
  height: auto;
  text-align: left;
  position: absolute;
  top: 220px;
  left: 430px;
}

#foto {
  width: 250px;
  float: right;
  margin-top: 165px;
  margin-right: 62px;
}

/* EXPERIÊNCIA */
#exp {
  position: absolute;
  top: 598px;
  left: calc(50% - 114px);
}

#segunda {
  position: relative;
  overflow: hidden;
}

#ligacaoDois {
  margin-left: calc(50% - 116px);
  display: block;
  margin-top: -5px;
}

#primExp {
  width: 500px;
  overflow: hidden;
  margin-left: 460px;
}

#segunda h2 {
  float: left;
}

#segunda h3 {
  margin: 2px auto 10px 65px;
}

#segunda p {
  clear: both;
}

.cinzento {
  color: rgb(90, 90, 90);
}

#ligacaoTres {
  margin-left: 140px;
  width: 350px;
  margin-top: 5px;
  clear: both;
  float: left;
}

#segExp {
  overflow: hidden;
  width: 400px;
  margin-left: -275px;
  margin-top: 128px;
  float: left;
}

#ligacaoQuatro {
  float: left;
  margin-top: 235px;
  margin-left: -10px;
}

#terExp {
  clear: both;
  width: 400px;
  margin-left: 625px;
}

/* EDUCAÇÃO */
#terceira {
  overflow: hidden;
}

#ligacaoCinco {
  width: 652px;
}

#tituloEduc {
  position: relative;
  overflow: hidden;
}

#educ {
  position: absolute;
  top: 128px;
  left: 80px;
}

#ligacaoSeis {
  margin-left: 35px;
  float: left;
}

#primEdu {
  float: left;
  margin-top: 125px;
  margin-left: -30px;
}

#segEdu {
  margin-top: 365px;
}

/* COMPETÊNCIAS */
#quarta {
  overflow: hidden;
}

#compDiv {
  overflow: hidden;
}

#compIcone {
  width: 72px;
  margin-left: 239px;
  float: left;
}

#comp {
  padding-top: 7px;
  margin-left: 20px;
  float: left;
}

#competencias {
  margin-top: 40px;
  clear: both;
}

.colComp {
  flex: 0 0 30% !important;
  max-width: 30% !important;
  margin: auto !important;
  background-color: rgba(211, 211, 211, 0.2);
  padding: 45px;
  border-radius: 20px;
}

#soft {
  padding: 45px;
}

#competencias h2 {
  font-family: Avenir-Demi;
}

#prog2 {
  margin-top: 32px;
}

/* PROJETOS */
#setima {
  overflow: hidden;
}

#ligacaoSete {
  margin-left: 300px;
}

#setima h1 {
  margin-left: 400px;
  margin-top: -40px;
}

#projetos {
  max-width: 1000px;
  margin: auto;
}

#projetos:first-of-type {
  padding-top: 60px;
}


#projetos img {
  width: 100%;
}

.col {
  position: relative;
  padding: 0;
}

#projetos h2 {
  color: white;
  text-transform: uppercase;
  font-size: 1rem;
  margin-top: 30px;
  margin-left: 30px;
}

#cell:hover #hoverp1 {
  opacity: 1;
  cursor: pointer;
}

#hoverp1 {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity .3s ease-in-out;
  -moz-transition: opacity .3s ease-in-out;
  -webkit-transition: opacity .3s ease-in-out;
  -o-transition: opacity .3s ease-in-out;
}

#nos:hover #hoverp2 {
  opacity: 1;
  cursor: pointer;
}

#hoverp2 {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity .3s ease-in-out;
  -moz-transition: opacity .3s ease-in-out;
  -webkit-transition: opacity .3s ease-in-out;
  -o-transition: opacity .3s ease-in-out;
}

#ifeel:hover #hoverp3 {
  opacity: 1;
  cursor: pointer;
}

#hoverp3 {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity .3s ease-in-out;
  -moz-transition: opacity .3s ease-in-out;
  -webkit-transition: opacity .3s ease-in-out;
  -o-transition: opacity .3s ease-in-out;
}

#hagrid:hover #hoverp4 {
  opacity: 1;
  cursor: pointer;
}

#hoverp4 {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity .3s ease-in-out;
  -moz-transition: opacity .3s ease-in-out;
  -webkit-transition: opacity .3s ease-in-out;
  -o-transition: opacity .3s ease-in-out;
}

#vieira:hover #hoverp5 {
  opacity: 1;
  cursor: pointer;
}

#hoverp5 {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity .3s ease-in-out;
  -moz-transition: opacity .3s ease-in-out;
  -webkit-transition: opacity .3s ease-in-out;
  -o-transition: opacity .3s ease-in-out;
}

#bio:hover #hoverp6 {
  opacity: 1;
  cursor: pointer;
}

#hoverp6 {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity .3s ease-in-out;
  -moz-transition: opacity .3s ease-in-out;
  -webkit-transition: opacity .3s ease-in-out;
  -o-transition: opacity .3s ease-in-out;
}

#filters:hover #hoverp7 {
  opacity: 1;
  cursor: pointer;
}

#hoverp7 {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity .3s ease-in-out;
  -moz-transition: opacity .3s ease-in-out;
  -webkit-transition: opacity .3s ease-in-out;
  -o-transition: opacity .3s ease-in-out;
}

#wash:hover #hoverp8 {
  opacity: 1;
  cursor: pointer;
}

#hoverp8 {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity .3s ease-in-out;
  -moz-transition: opacity .3s ease-in-out;
  -webkit-transition: opacity .3s ease-in-out;
  -o-transition: opacity .3s ease-in-out;
}

#ntc:hover #hoverp9 {
  opacity: 1;
  cursor: pointer;
}

#hoverp9 {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity .3s ease-in-out;
  -moz-transition: opacity .3s ease-in-out;
  -webkit-transition: opacity .3s ease-in-out;
  -o-transition: opacity .3s ease-in-out;
}

#footour:hover #hoverp10 {
  opacity: 1;
  cursor: pointer;
}

#hoverp10 {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity .3s ease-in-out;
  -moz-transition: opacity .3s ease-in-out;
  -webkit-transition: opacity .3s ease-in-out;
  -o-transition: opacity .3s ease-in-out;
}

#copek:hover #hoverp11 {
  opacity: 1;
  cursor: pointer;
}

#hoverp11 {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity .3s ease-in-out;
  -moz-transition: opacity .3s ease-in-out;
  -webkit-transition: opacity .3s ease-in-out;
  -o-transition: opacity .3s ease-in-out;
}

#blunder:hover #hoverp12 {
  opacity: 1;
  cursor: pointer;
}

#hoverp12 {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity .3s ease-in-out;
  -moz-transition: opacity .3s ease-in-out;
  -webkit-transition: opacity .3s ease-in-out;
  -o-transition: opacity .3s ease-in-out;
}

#ubi:hover #hoverp13 {
  opacity: 1;
  cursor: pointer;
}

#hoverp13 {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity .3s ease-in-out;
  -moz-transition: opacity .3s ease-in-out;
  -webkit-transition: opacity .3s ease-in-out;
  -o-transition: opacity .3s ease-in-out;
}

#makers:hover #hoverp14 {
  opacity: 1;
  cursor: pointer;
}

#hoverp14 {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity .3s ease-in-out;
  -moz-transition: opacity .3s ease-in-out;
  -webkit-transition: opacity .3s ease-in-out;
  -o-transition: opacity .3s ease-in-out;
}

#branca {
  width: 100%;
  height: 100%;
  background-color: white;
}

.tit {
  font-size: 1.2rem;
  font-family: Avenir-Medium;
  color: rgb(244, 160, 137);
}

.pmodal {
  font-size: 1rem;
}

.modalBtn {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 3rem;
}

.modalImg {
  width: 100%;
  margin-top: 10px;
}

.modalMockups {
  width: 80%;
  margin: 30px auto;
  display: block;
}

/* DOWNLOAD CV */
#download {
  clear: both;
  margin: 80px auto 20px auto;
  padding: 20px 30px;
  color: white;
  background-color: rgb(244, 160, 137);
  font-family: Avenir-Demi;
  font-size: 1.4rem;
  border-radius: 15px;
  display: block;
  text-transform: uppercase;
}

/* FOOTER */
#footer {
  width: 100%;
  height: 400px;
  background-color: #252525;
}

#footer img {
  margin-left: 35%;
}

#footer h1 {
  color: white;
  text-align: center;
  margin-top: 20px;
}

#footer p {
  color: white;
  text-align: center;
  margin-top: 10px;
}

#iconesFooter {
  width: 150px;
  overflow: hidden;
  margin: auto;
  margin-top: 60px;
}

#iconesFooter .icone {
  margin: 10px;
}

/* MEDIA QUERIES */
@media screen and (max-width: 1200px) {
  #ligacaoUm {
    margin-left: 50px;
  }

  #sobre {
    left: 475px;
  }

  #foto {
    width: 220px;
    position: absolute;
    top: 0;
    left: 60px;
  }

  #primeira p {
    left: 475px;
  }

  #ligacaoDois {
    margin-left: 492px;
  }

  #exp {
    left: 50%;
  }
}

@media screen and (max-width: 1000px) {

  #hoverp1,
  #hoverp2,
  #hoverp3,
  #hoverp4,
  #hoverp5,
  #hoverp6,
  #hoverp7,
  #hoverp8,
  #hoverp9,
  #hoverp10,
  #hoverp11,
  #hoverp12,
  #hoverp13,
  #hoverp14 {
    opacity: 1;
  }

  #ligacaoDois {
    margin-left: 532px;
  }

  #primExp {
    margin-left: 450px;
    width: 300px;
  }

  #segExp {
    width: 300px;
  }

  #terExp {
    width: 300px;
    margin-left: 560px;
  }

  #ligacaoCinco {
    width: 620px;
    margin-left: 40px;
  }

  #educ {
    top: 124px;
    left: 114px;
  }

  #ligacaoSeis {
    margin-left: 70px;
    width: 350px;
  }

  #primEdu {
    margin-top: 108px;
  }

  #segEdu {
    margin-top: 275px;
  }

  #compIcone {
    margin-left: 214px;
  }

  .colComp {
    flex: 0 0 45% !important;
    max-width: 45% !important;
  }

  #exp {
    left: 58%;
  }
}

@media screen and (max-width: 900px) {
  #ligacaoUm {
    width: 70%;
  }

  #sobre {
    left: 45%;
    top: 150px;
  }

  #primeira p {
    left: 45%;
    width: 45%;
    top: 190px;
    min-width: 150px;
  }

  #foto {
    display: none;
  }

  #ligacaoDois {
    margin-left: 392px;
  }

  #primExp {
    margin-left: 367px;
    width: 300px;
  }

  #ligacaoTres {
    margin-left: 100px;
  }

  #terExp {
    margin-left: 450px;
  }
}

@media screen and (max-width: 875px) {
  #primeira {
    position: static;
  }

  #ligacaoUm,
  #ligacaoDois,
  #ligacaoTres,
  #ligacaoQuatro,
  #ligacaoCinco,
  #ligacaoSeis {
    display: none;
  }

  #sobre {
    position: static;
    margin-left: 80px;
    margin-top: 10px;
  }

  #primeira p {
    position: static;
    margin-left: 80px;
    width: 450px;
  }

  #aviao {
    display: block;
    width: 150px;
    margin-top: 50px;
  }

  #exp {
    position: static;
    margin-top: 105px;
    margin-left: 180px;
  }

  #mala {
    display: block;
    width: 70px;
    margin-left: 80px;
    margin-top: 80px;
    float: left;
  }

  #primExp {
    margin-left: 80px;
    width: 400px;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  #segExp {
    width: 450px;
    margin-left: 80px;
    margin-top: 0;
    margin-bottom: 30px;
  }

  #terExp {
    margin-left: 80px;
    width: 450px;
  }

  #chapeu {
    display: block;
    width: 90px;
    float: left;
  }

  #terceira {
    margin-top: 80px;
    margin-left: 80px;
  }

  #educ {
    position: static;
    float: left;
    margin-top: 35px;
    margin-left: 30px;
  }

  #primEdu {
    float: none;
    clear: both;
    margin-left: 0;
    margin-top: 30px;
  }

  #segEdu {
    margin-top: 30px;
  }

  #quarta {
    margin-top: 80px;
  }

  #compDiv {
    margin-left: 80px;
  }

  #compIcone {
    margin-left: 0;
  }

  #ligacaoSete {
    margin-left: 15%;
    width: 265px;
  }

  #setima h1 {
    margin-left: 400px;
    margin-top: -36px;
  }
}

@media screen and (max-width: 715px) {
  .colComp {
    flex: 0 0 80% !important;
    max-width: 80% !important;
  }

  #competencias {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 650px) {
  #icones {
    right: 10px;
  }

  .icone {
    margin: 10px 5px;
    opacity: 1;
  }
}

@media screen and (max-width: 560px) {
  #primeira p {
    width: 70%;
  }

  #primExp {
    width: 70%;
  }

  #segExp {
    width: 70%;
  }

  #terExp {
    width: 70%;
  }

  #primEdu {
    width: 70%;
  }

  #segEdu {
    width: 70%;
  }

  #setima h1 {
    margin-left: 100px;
  }
}

@media screen and (max-width: 425px) {

  #competencias p,
  #soft p {
    margin-bottom: 5px;
  }

  #primeira p,
  #sobre,
  #mala,
  #segunda,
  #terceira,
  #compDiv {
    margin-left: 40px;
  }

  #primeira p {
    width: 75%;
  }

  #exp {
    margin-left: 140px;
  }

  #primExp,
  #segExp,
  #terExp {
    margin-left: 0;
    width: 75%;
  }

  #primEdu,
  #segEdu {
    margin-left: 0;
    width: 80%;
  }

  #comp {
    padding-top: 7px;
    margin-left: 10px;
  }

  #compDiv {
    width: 100%;
  }

  #projetos h2 {
    margin: 20px 10px 0 20px;
    /*font-size: 0.9rem;*/
  }
}

@media screen and (max-width: 380px) {
  .icone {
    margin: 10px 2px;
    width: 25px;
  }
}

@media screen and (max-width: 340px) {
  #footer img {
    margin-left: 14%;
  }

  #ligacaoSete {
    margin-left: 12%;
  }

  #educ {
    left: 40px;
  }
  
  #comp {
    font-size: 1.4rem;
  }
}